import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { ScheduleDynamicSidebarComponent } from '../../shared/schedule-department/schedule-sidebar/n-period/schedule-dynamic-sidebar.component';

@Injectable()
export class ScheduleSidebarService {
  public sideBarStateChange = new Subject();
  private sideBar: SidebarComponent;
  private scheduleSideBar: ScheduleDynamicSidebarComponent;
  private departmentId$ = new BehaviorSubject<string>(null);

  public setSideBar(sidebar: SidebarComponent, scheduleSidebar: ScheduleDynamicSidebarComponent): void {
    this.sideBar = sidebar;
    this.scheduleSideBar = scheduleSidebar;
  }

  public openSideBar(): void {
    this.sideBar.open();
    this.scheduleSideBar.forceScroll();
    this.sideBarStateChange.next(null);
  }

  public closeSideBar(): void {
    this.sideBar.close();
    this.sideBarStateChange.next(null);
  }

  public isOpen(): boolean {
    return this.sideBar && this.sideBar.opened;
  }

  public getSelectedDepartment(): BehaviorSubject<string> {
    return this.departmentId$;
  }

  public setDepartmentId(departmentId: string): void {
    this.departmentId$.next(departmentId);
  }

  public toggleSideBar(): void {
    this.sideBar.toggle();
    this.scheduleSideBar.forceScroll();
    this.sideBarStateChange.next(null);
  }

  public get hasSidebar(): boolean {
    return !!this.sideBar;
  }
}
